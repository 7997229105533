import React from "react";
import { Routes, Route } from "react-router-dom";
import About from "./Pages/about";
import Blog from "./Pages/blog";
import Contact from "./Pages/contact";
import Gallery from "./Pages/gallery";
import Home from "./Pages/home";
import Footer from "./Pages/Layouts/footer";
import Header from "./Pages/Layouts/header";
import Service from "./Pages/service";
import ServiceSingle from "./Pages/serviceSingle";
import SingleService from "./Pages/Components/ServiceSingle";

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path={"/about"} element={<About />} />
        <Route path={"/service"} element={<Service />} />
        <Route path={"/blog"} element={<Blog />} />
        <Route path={"/gallery"} element={<Gallery />} />
        <Route path={"/contact"} element={<Contact />} />
        <Route path={"/serviceSingle"} element={<ServiceSingle />} />
        <Route path={"/service-single/:id"} element={<SingleService />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
