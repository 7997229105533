import React from 'react'
import { Link } from 'react-router-dom'
import AboutImg1 from '../../../assets/img/home/about1.jpg'
import AboutImg2 from '../../../assets/img/home/about2.jpg'
import AboutImg3 from '../../../assets/img/home/about3.jpg'
import {
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaInstagram,
  FaEnvelope
} from 'react-icons/fa'
function HomeAbout () {
  return (
    <>
      <div className='bg-[#E6E8D6] py-16'>
        <div className='max-w-screen-xl border mx-auto py-3'>
          <div className='flex flex-col md:flex-row'>
            <div className='bg-[#6A6E49] p-4 border w-full md:w-1/2 flex justify-center items-center'>
              <img src={AboutImg1} alt={''} />
            </div>
            <div className='w-full md:w-1/2 flex flex-col justify-center space-y-10 p-8'>
              <h3 className='text-xl text-[#6A6E49] font-bold font-press-start'>
                О нас
              </h3>
              <p className='text-justify text-[#6A6E49] font-bold font-press-start-cardo opacity-80'>
                Добрый день! Меня зовут Светлана и я являюсь основательницей
                Дома Цветов «Lan‘s» Я поняла, что не могу жить без цветов, когда
                устроилась работать в цветочный магазин, где и обучилась
                флористике. Я всегда продолжала двигаться к своей мечте: своему
                собственному Дому цветов - месту, где будут жить самые красивые
                букеты.
              </p>
            </div>
          </div>
          <div className='flex flex-col md:flex-row'>
            <div className='w-full md:w-1/2 flex flex-col justify-center space-y-10 p-8'>
              {/* <h3 className='text-xl text-[#6A6E49] font-bold font-press-start'>
                Школа цветов Ланы
              </h3> */}
              <p className='text-justify text-[#6A6E49] font-bold font-press-start-cardo opacity-80'>
                Во время двухлетнего перерыва мысль о собственном магазине все
                еще не отпускала. И, благодаря поддержке всей семьи, уже через 7
                месяцев я открыла первый Цветочный дом «Lan's» по адресу
                Херсонская улица, 18 Сейчас, спустя два года, мы уже развиваемся
                в сторону открытия второго магазина. Все благодаря клиентам,
                которые любят и ценят наше творчество, то, что мы создаем с
                большой любовью и заботой. И, конечно, благодаря близким и
                родным, чья поддержка помогала нам справиться со всеми
                трудностями с улыбкой на лице! Спасибо за доверие и выбор именно
                нас! <br /> С любовью Lan’s
              </p>
            </div>
            <div className='bg-[#6A6E49] p-4 border w-full md:w-1/2 flex justify-center items-center'>
              <img src={AboutImg2} alt={''} />
            </div>
          </div>
          <div className='flex flex-col md:flex-row'>
            <div className='bg-[#6A6E49] p-4 border w-full md:w-1/2 flex justify-center items-center'>
              <img src={AboutImg3} alt={''} />
            </div>
            <div className='w-full md:w-1/2 flex flex-col justify-center space-y-4 p-8'>
              <h3 className='text-xl text-[#6A6E49] font-bold font-press-start'>
                Наш магазин
              </h3>
              <div>
                <ul className=''>
                  <li className='flex group mt-2 cursor-pointer'>
                    <FaMapMarkerAlt className='text-[#6A6E49] relative translate-y-1  group-hover:-translate-y-1 transition-all duration-500' />{' '}
                    <span className='ml-4 text-black font-bold font-press-start-cardo'>
                      Херсонская 18
                    </span>
                  </li>
                  <li className='flex group mt-2 cursor-pointer'>
                    <FaPhoneAlt className='text-[#6A6E49] relative translate-y-1  group-hover:-translate-y-1 transition-all duration-500' />{' '}
                    <span className='ml-4 text-black font-bold font-press-start-cardo'>
                      +79854359584
                    </span>
                  </li>
                  <li className='flex group mt-2 cursor-pointer'>
                    <FaInstagram className='text-[#6A6E49] relative translate-y-1  group-hover:-translate-y-1 transition-all duration-500' />{' '}
                    <span className='ml-4 text-black font-bold font-press-start-cardo'>
                      @lansfloart
                    </span>
                  </li>

                  <li className='flex group mt-2 cursor-pointer'>
                    <FaEnvelope className='text-[#6A6E49] relative translate-y-1  group-hover:-translate-y-1 transition-all duration-500' />{' '}
                    <span className='ml-4 text-black font-bold font-press-start-cardo'>
                      info@lansfloart.ru
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HomeAbout
