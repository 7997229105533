import React from "react";
import { useParams } from "react-router-dom";
import { data } from "../../data/service";

import LightGallery from "lightgallery/react";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";

export default function ServiceSingle() {
  const { id } = useParams();
  const services = data.find((item) => item.id == id);
  return (
    <>
      <div className="w-full bg-[#6A6E49] py-12">
        <div className="container mx-auto">
          <div className="text-white text-2xl font-bold font-press-start-lato">
            {services.title}
          </div>
          <div className="mt-12">
            <LightGallery
              speed={500}
              plugins={[lgThumbnail, lgZoom]}
              className="bg-dark"
            >
              {services.images &&
                services.images.map((item, index) => {
                  return (
                    <a
                      href={item.href}
                      key={index}
                      className={"w-1/2 md:w-1/4 p-2"}
                    >
                      <img
                        alt={item.alt}
                        src={item.href}
                        className="h-full w-full object-cover"
                      />
                    </a>
                  );
                })}
            </LightGallery>
          </div>
        </div>
      </div>
    </>
  );
}
