import React from "react";
import { Link } from "react-router-dom";
import Service1Img from "../../../assets/img/home/service1.webp";
import Service2Img from "../../../assets/img/home/service2.webp";
import Service3Img from "../../../assets/img/home/service3.jpg";
import { data } from "../../../data/service";

function HomeService() {
  return (
    <>
      <div className="w-full bg-[#6A6E49] py-12">
        <div className="text-3xl text-white font-press-start text-center py-5">
          Услуги
        </div>
        <div className="mt-8 container mx-auto gap-x-8  grid md:grid-cols-2 lg:grid-cols-4">
          {data &&
            data.map((item, index) => {
              return (
                <div key={index} className="">
                  <div className="h-full rounded-lg overflow-hidden">
                    <img
                      src={item.image}
                      className="h-full w-full object-cover transition-all duration-300 hover:scale-110 cursor-pointer"
                      alt="Service Image"
                    />
                  </div>
                  <div>
                    <p className="relative py-2 hover:-translate-y-1 transition-all duration-300">
                      <Link
                        to={`service-single/${item.id}`}
                        className={
                          "font-press-start-lato text-black font-bold  uppercase  "
                        }
                      >
                        {item.title}
                      </Link>
                    </p>
                  </div>
                </div>
              );
            })}
          {/* <div className='sm:w-1/2 mx-auto lg:w-1/3'>
            <img src={Service1Img} className='max-sm:w-64 rounded-t-[80px] overflow-hidden' alt='' />
          </div>
          <div className='sm:w-1/2 mx-auto lg:w-1/3 flex flex-col'>
            <ul className='lg:px-12 h-full flex flex-col justify-center space-y-4 text-xl text-white font-bold font-press-start'>
              <li className='transition-all duration-300 ease-linear cursor-pointer hover:translate-x-3'>
                Сборка букетов и композиции
              </li>
              <li className='transition-all duration-300 ease-linear cursor-pointer hover:translate-x-3'>
                Оформление мероприятие
              </li>
              <li className='transition-all duration-300 ease-linear cursor-pointer hover:translate-x-3'>
                Надувание Шаров
              </li>
              <li className='transition-all duration-300 ease-linear cursor-pointer hover:translate-x-3'>
                Игрушки и подарки
              </li>
              <li className='transition-all duration-300 ease-linear cursor-pointer hover:translate-x-3'>
                Декор для вашего дома
              </li>
            </ul>
            <div className='flex max-lg:my-6 justify-end relative hover:-translate-y-1 transition-all duration-300'>
              <Link to={'/service'} className='font-press-start-lato text-black font-bold  uppercase'>
                узнать больше
              </Link>
            </div>
          </div>
          <div className='sm:w-1/2 mx-auto lg:w-1/3'>
            <img src={Service3Img} className='max-sm:w-64 rounded-t-[80px] overflow-hidden' alt='' />
          </div> */}
        </div>
      </div>
    </>
  );
}
export default HomeService;
