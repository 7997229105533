import React from "react";
import HomeFirst1 from "../../../assets/img/home/home1.png";
function HomeFirst() {
    return (
        <>
            <div className="w-full bg-[#cac3b9]">
                <div className="max-w-screen-xl flex flex-col md:flex-row mx-auto py-5">
                    <div className="w-full md:w-1/2 Content-Text">
                        <h1>Мы рассказываем <br /> истории
                            с
                            <span className="txt-rotate" data-period="2000"
                                data-rotate='[ "цветами", "растениями", "воздушными шарами", "свечами", "вазами" ]'></span>
                        </h1>
                    </div>
                    <div className="w-full md:w-1/2 flex justify-center">
                        <img src={HomeFirst1} alt={'Lana FloArt'} />
                    </div>
                </div>
            </div>
        </>
    )
}
export default HomeFirst