import React from "react";
// import "../assets/js/script";
import * as te from 'tw-elements';
import DesignBlog from "./Components/BlogComponents/designblog";
import { Link } from 'react-router-dom';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
function Blog() {
    return (
        <>
            <div className="bg-[#cabdb4] pb-12">
                <div className="max-w-screen-xl mx-auto">
                    <Tabs className={''}>
                        <div className="w-[100%]">
                        <TabList className={'flex flex-col md:flex-row'}>
                            <Tab className={'text-center font-bold font-press-start bg-[#6A6E49] cursor-pointer py-3 border-b-2 transition-all duration-300 hover:bg-white focus:border-none md:w-1/3'}>Title 1</Tab>
                            <Tab className={'text-center font-bold font-press-start bg-[#6A6E49] cursor-pointer py-3 border-b-2 transition-all duration-300 hover:bg-white focus:border-none md:w-1/3'}>Title 2</Tab>
                            <Tab className={'text-center font-bold font-press-start bg-[#6A6E49] cursor-pointer py-3 border-b-2 transition-all duration-300 hover:bg-white focus:border-none md:w-1/3'}>Title 3</Tab>
                        </TabList>
                        </div>
                        <div className="w-[85%]">
                        <TabPanel className={'px-6 box-border'}>
                        {/* <DesignBlog /> */}
                        1
                        </TabPanel>
                        <TabPanel className={'px-6 box-border'}>
                        {/* <DesignBlog /> */}
                        2
                        </TabPanel>
                        <TabPanel className={'px-6 box-border'}>
                            {/* <DesignBlog /> */}
                        3
                        </TabPanel>

                        </div>
                    </Tabs>
                </div>
            </div>

            {/* <div className="bg-[#cabdb4]">
                <div className="max-w-screen-xl mx-auto ">
                    <div className="flex flex-col md:flex-row items-start">
                        <ul
                            className="mr-4 flex flex-row list-none md:flex-col flex-wrap pl-0 bg-[#6A6E49]"
                            role="tablist"
                            data-te-nav-ref>
                            <li role="presentation" class="flex-grow text-center">
                                <a
                                    href="#tabs-home03"
                                    className="block border text-white hover:text-[#6A6E49] border-x-0 border-t-0 border-b-2 border-transparent  text-xs  uppercase leading-tight hover:isolate hover:border-transparent hover:bg-neutral-100 focus:isolate focus:border-transparent data-[te-nav-active]:border-primary data-[te-nav-active]:text-primary font-bold font-press-start"
                                    data-te-toggle="pill"
                                    data-te-target="#tabs-home03"
                                    data-te-nav-active
                                    role="tab"
                                    aria-controls="tabs-home03"
                                    aria-selected="true"
                                >цветы</a>
                            </li>
                            <li role="presentation" class="flex-grow text-center">
                                <a
                                    href="#tabs-profile03"
                                    className="focus:border-transparen block border-x-0 hover:text-[#6A6E49] border-t-0 border-b-2 border-transparent px-7 text-xs  uppercase leading-tight text-white hover:isolate hover:border-transparent hover:bg-neutral-100 focus:isolate data-[te-nav-active]:border-primary data-[te-nav-active]:text-primary font-bold font-press-start"
                                    data-te-toggle="pill"
                                    data-te-target="#tabs-profile03"
                                    role="tab"
                                    aria-controls="tabs-profile03"
                                    aria-selected="false"
                                >растения</a>
                            </li>
                            <li role="presentation" className="flex-grow text-center border">
                                <Link
                                    to="/#tabs-messages03"
                                    class="block border-x-0 border-t-0 border-b-2 border-transparent hover:text-[#6A6E49] px-7 text-xs  uppercase leading-tight text-white font-press-start font-bold hover:isolate hover:border-transparent hover:bg-neutral-100 focus:isolate focus:border-transparent data-[te-nav-active]:border-primary data-[te-nav-active]:text-primary"
                                    data-te-toggle="pill"
                                    data-te-target="#tabs-messages03"
                                    role="tab"
                                    aria-controls="tabs-messages03"
                                    aria-selected="false"
                                >дизайн</Link>
                            </li>

                        </ul>
                        <div className="my-0">
                            <div
                                className="hidden opacity-0 opacity-100 transition-opacity duration-150 ease-linear data-[te-tab-active]:block"
                                id="tabs-home03"
                                role="tabpanel"
                                aria-labelledby="tabs-home-tab03"
                                data-te-tab-active>
                                Tab 1 content
                            </div>
                            <div
                                class="hidden opacity-0 transition-opacity duration-150 ease-linear data-[te-tab-active]:block"
                                id="tabs-profile03"
                                role="tabpanel"
                                aria-labelledby="tabs-profile-tab03">
                                Tab 2 content
                            </div>
                            <div
                                class="hidden opacity-0 transition-opacity duration-150 ease-linear data-[te-tab-active]:block"
                                id="tabs-messages03"
                                role="tabpanel"
                                aria-labelledby="tabs-profile-tab03">
                                <DesignBlog />
                            </div>
                            <div
                                class="hidden opacity-0 transition-opacity duration-150 ease-linear data-[te-tab-active]:block"
                                id="tabs-contact03"
                                role="tabpanel"
                                aria-labelledby="tabs-contact-tab03">
                                Tab 4 content
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    )
}
export default Blog