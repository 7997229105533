import React from 'react'
import AboutImg1 from '../assets/img/home/about1.jpg'
import AboutImg2 from '../assets/img/home/about2.jpg'
import BgImage from '../assets/img/bg.webp'
import OurTeam from './Components/AboutComponents/ourTeam'
function About () {
  return (
    <>
      <div
        className='w-full py-64 bg-fixed bg-center bg-cover'
        style={{ backgroundImage: `url(${BgImage})` }}
      ></div>
      <div className='bg-[#E6E8D6] py-16'>
        <div className='max-w-screen-xl mx-auto border'>
          <h3 className='text-xl text-[#6A6E49] font-bold font-press-start text-center'>
            О нас
          </h3>
        </div>
        <div className='max-w-screen-xl border mx-auto py-3 text-justify text-[#6A6E49] font-bold font-press-start-cardo opacity-80 flex flex-col space-y-6 max-xl:px-6'>
          <p>
            {' '}
            Добрый день! Меня зовут Светлана и я являюсь основательницей Дома
            Цветов «Lan‘s»
          </p>
          <p>
            Я поняла, что не могу жить без цветов, когда устроилась работать в
            цветочный магазин, где и обучилась флористике. Я всегда продолжала
            двигаться к своей мечте: своему собственному Дому цветов - месту,
            где будут жить самые красивые букеты.
          </p>
          <p>
            Во время двухлетнего перерыва мысль о собственном магазине все еще
            не отпускала. И, благодаря поддержке всей семьи, уже через 7 месяцев
            я открыла первый Цветочный дом «Lan's» по адресу Херсонская улица,
            18
          </p>
          <p>
            Сейчас, спустя два года, мы уже развиваемся в сторону открытия
            второго магазина. Все благодаря клиентам, которые любят и ценят наше
            творчество, то, что мы создаем с большой любовью и заботой. И,
            конечно, благодаря близким и родным, чья поддержка помогала нам
            справиться со всеми трудностями с улыбкой на лице!
          </p>
          <p>Спасибо за доверие и выбор именно нас!</p>
          <p>С любовью Lan’s</p>
        </div>
        <div className='max-w-screen-xl mx-auto'>
          <div className='text-xl text-[#6A6E49] font-bold font-press-start text-center'>
            Контакты
          </div>
          <div className='mt-4 text-2xl font-press-start-cardo'>Режим работы</div>
          <ul>
            <li className='pl-4 text-[#6A6E49] py-2'>
              {' '}
              Работаем ежедневно с 10:00 до 22:00{' '}
            </li>
            <li className='pl-4 text-[#6A6E49] py-2'>
              {' '}
              Приём заказов осуществляется до 22:00{' '}
            </li>
            <li className='pl-4 text-[#6A6E49] py-2'>
              {' '}
              Доставка заказов осуществляется с 7:00 до 24:00{' '}
            </li>
            <li className='pl-4 text-[#6A6E49] py-2'>
              {' '}
              Доставка от 1 часа . Подробнее <a href="https://shop.lansfloart.ru/%d0%b4%d0%be%d1%81%d1%82%d0%b0%d0%b2%d0%ba%d0%b0-%d0%b8-%d0%be%d0%bf%d0%bb%d0%b0%d1%82%d0%b0/" target='_blank' className='font-bold text-black'>здесь</a>
            </li>
          </ul>
          <div className='mt-4 text-2xl font-press-start-cardo'>Реквизиты</div>
          <ul>
            <li className='pl-4 text-[#6A6E49] py-2'>
            ИП Петросян Светлана Самвеловна
            </li>
            <li className='pl-4 text-[#6A6E49] py-2'>
              ИНН 772998122500
            </li>
            <li className='pl-4 text-[#6A6E49] py-2'>
            ОГРНИП 321774600357688
            </li>
          </ul>
        </div>
      </div>
      {/* <OurTeam /> */}
    </>
  )
}
export default About
