export const data = [
  {
    image:
      "https://shop.lansfloart.ru/wp-content/uploads/2024/01/IMG_7620-scaled.jpg",
    href: "https://shop.lansfloart.ru/product/%d1%82%d1%80%d0%b8%d0%be%d0%b1%d1%83%d0%ba%d0%b5%d1%82-%d0%b2%d0%b5%d1%81%d0%b5%d0%bd%d0%bd%d0%b5%d0%b5-%d0%be%d1%87%d0%b0%d1%80%d0%be%d0%b2%d0%b0%d0%bd%d0%b8%d0%b5/",
    price: "От 3050 ₽",
    name: "Весеннее очарование",
  },
  {
    image:
      "https://shop.lansfloart.ru/wp-content/uploads/2024/02/IMG_9563-600x800.jpg",
    href: "https://shop.lansfloart.ru/product/%d0%bc%d0%be%d0%bd%d0%be%d0%b1%d1%83%d0%ba%d0%b5%d1%82-%d1%81%d0%ba%d1%80%d0%be%d0%bc%d0%bd%d0%be%d1%81%d1%82%d1%8c/",
    price: "От 2450 ₽",
    name: "Скромность",
  },
  {
    image:
      "https://shop.lansfloart.ru/wp-content/uploads/2024/01/IMG_8982-600x800.jpg",
    href: "https://shop.lansfloart.ru/product/%d1%82%d1%80%d0%b8%d0%be%d0%b1%d1%83%d0%ba%d0%b5%d1%82-%d0%b1%d0%b5%d0%b7%d0%bc%d1%8f%d1%82%d0%b5%d0%b6%d0%bd%d0%be%d1%81%d1%82%d1%8c/",
    price: "От 2400 ₽",
    name: "Безмятежность",
  },
  {
    image:
      "https://shop.lansfloart.ru/wp-content/uploads/2024/02/IMG_9706-600x800.jpg",
    href: "https://shop.lansfloart.ru/product/%d0%bc%d0%be%d0%bd%d0%be%d0%b1%d1%83%d0%ba%d0%b5%d1%82-%d0%b2%d0%b5%d1%87%d0%bd%d0%b0%d1%8f-%d0%bb%d1%8e%d0%b1%d0%be%d0%b2%d1%8c/",
    price: "От 3700 ₽",
    name: "Вечная любовь",
  },
];
