import React from "react"
import Logo from '../../assets/img/new_logo.png';
import LocImg from '../../assets/img/location.png';
import Telimg from "../../assets/img/phone.png";
import Envimg from "../../assets/img/envelope.png";
import Instaimg from "../../assets/img/insta.png";
import { Link } from "react-router-dom";

function Footer() {
    return (
        <>
            <div className="bg-[#F1EFEB] w-full py-5">
                <div className="max-w-screen-xl mx-auto flex">
                    <img src={Logo} className={'w-36'} />
                </div>
                <div className="max-w-screen-xl mx-auto flex flex-wrap md:flex-nowrap">
                    <div className="flex justify-center md:justify-start w-1/2 md:w-1/4">
                        <ul className="mt-5 flex flex-col space-y-3">
                            <li className="flex font-bold font-press-start group cursor-pointer">
                                <img src={LocImg} className="transition-all duration-300 group-hover:-translate-y-2" /> <span className="ml-4">Херсонская 18</span>
                            </li>
                            <li className="flex font-bold font-press-start group cursor-pointer">
                                <img src={Telimg} className="transition-all duration-300 group-hover:-translate-y-2 w-6 rotate-180" />
                                <a href={'tel:+79854359584'} className={'ml-4'}>+79854359584</a>
                            </li>
                            <li className="flex font-bold font-press-start group cursor-pointer">
                                <img src={Instaimg} className="transition-all duration-300 group-hover:-translate-y-2 w-6 " />
                                <a href={'https://www.instagram.com/lansfloart/?igshid=NmE0MzVhZDY%3D'} className={'ml-4'}>lansfloart</a>
                            </li>
                            <li className="flex font-bold font-press-start group cursor-pointer">
                                <img src={Envimg} className="transition-all duration-300 group-hover:-translate-y-2 w-6 " />
                                <a href={'mailto:info@lansfloart.ru'} className={'ml-4'}>info@lansfloart.ru</a>
                            </li>
                        </ul>
                    </div>
                    <div className="flex justify-center md:justify-start w-1/2 md:w-1/4">
                    <ul className="mt-5 flex flex-col space-y-3">
                            <li className="flex font-bold font-press-start group cursor-pointer">
                                <Link to={''} className="transition-all duration-300 group-hover:-translate-y-2">Главная</Link>   
                            </li>
                            <li className="flex font-bold font-press-start group cursor-pointer">
                                <Link to={'/about'} className="transition-all duration-300 group-hover:-translate-y-2">О нас</Link>   
                            </li>
                            <li className="flex font-bold font-press-start group cursor-pointer">
                                <Link to={'/about'} className="transition-all duration-300 group-hover:-translate-y-2">Услуги</Link>   
                            </li>
                            <li className="flex font-bold font-press-start group cursor-pointer">
                                <Link to={'/blog'} className="transition-all duration-300 group-hover:-translate-y-2">Блог</Link>   
                            </li>
                            <li className="flex font-bold font-press-start group cursor-pointer">
                                <Link to={'/'} className="transition-all duration-300 group-hover:-translate-y-2">Магазин</Link>   
                            </li>
                            <li className="flex font-bold font-press-start group cursor-pointer">
                                <Link to={'/gallery'} className="transition-all duration-300 group-hover:-translate-y-2">Галерея</Link>   
                            </li>
                            <li className="flex font-bold font-press-start group cursor-pointer">
                                <Link to={'/contact'} className="transition-all duration-300 group-hover:-translate-y-2">Связаться с нами</Link>   
                            </li>
                        </ul>
                    </div>
                    <div className="w-full md:w-2/4 mt-5">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2251.1203164576095!2d37.563577625367934!3d55.65211625406104!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46b54d2b28d526c7%3A0xf7609a7b54ea237a!2z0KXQtdGA0YHQvtC90YHQutCw0Y8g0YPQuy4sIDE4LCBNb3NrdmEsIFJ1c3NpYSwgMTE3NDYx!5e0!3m2!1sen!2sam!4v1677612304641!5m2!1sen!2sam" width="100%" height="100%"  allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Footer