import React from "react";
import Logo from "../../assets/img/new_logo.png";
import { Link } from "react-router-dom";
function Header() {
  const [open, setOpen] = React.useState(false);
  const Close = () => setOpen(false);
  return (
    <>
      <div className="bg-[#cabdb4] hidden md:flex py-3 flex justify-center">
        <img src={Logo} className={"w-36"} />
      </div>
      <div className="relative bg-[#cabdb4] pb-3  sm:mr-0 sm:ml-0 z-50">
        <div className="px-5 mx-auto max-w-7xl md:px-0">
          <div className="flex items-center justify-between py-2 md:justify-center md:space-x-10">
            <div className="bg-[#cabdb4] block md:hidden py-3 flex justify-center">
              <img src={Logo} className={"w-36"} />
            </div>

            <div className="md:hidden">
              <button
                type="button"
                className="inline-flex items-center justify-center text-white text-gray-400 rounded-md focus:outline-none"
                onClick={() => setOpen(!open)}
              >
                {/* <span className="sr-only">Open menu</span> */}
                <svg
                  className="w-6 h-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
            </div>
            <nav className="relative z-50 hidden space-x-10 md:flex">
              <Link to="/" className="nav-link font-press-start">
                Главная
              </Link>
              <Link to="/about" className="nav-link font-press-start">
                О нас
              </Link>
              <Link to="/service" className="nav-link font-press-start">
                Услуги
              </Link>
              {/* <Link
                to="/blog"
                className="nav-link font-press-start"
              >
                Блог
              </Link> */}
              <a
                href="https://shop.lansfloart.ru/product-category/%d0%b2%d1%81%d0%b5-%d1%86%d0%b2%d0%b5%d1%82%d1%8b/"
                className="nav-link font-press-start"
              >
                Магазин
              </a>
              <Link to="/gallery" className="nav-link font-press-start">
                Галерея
              </Link>
              <Link to="/contact" className="nav-link font-press-start">
                Связаться с нами
              </Link>
            </nav>
          </div>
        </div>

        <div
          className={
            open
              ? "h-96 overflow-hidden w-full absolute top-0 bg-[#cabdb4] transition-all duration-300 ease-linear"
              : "h-0 w-full absolute top-0 overflow-hidden bg-[#cabdb4] transition-all duration-300 ease-linear"
          }
        >
          <div className="flex items-center justify-between pr-6">
            <div>
              <img className="w-36" src={Logo} alt="Workflow" />
            </div>
            <div className="-mr-2 bg-[#cabdb4]">
              <button
                type="button"
                className="bg-[#cabdb4] rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500  focus:outline-none focus:bg-none"
                onClick={() => setOpen(!open)}
              >
                <span className="sr-only">Close menu</span>
                <svg
                  className="w-6 h-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
          <nav className="relative z-50 flex flex-col px-6 space-y-3 ">
            <Link to="/" className="nav-link font-press-start">
              Главная
            </Link>
            <Link to="/about" className="nav-link font-press-start">
              О нас
            </Link>
            <Link to="/service" className="nav-link font-press-start">
              Услуги
            </Link>
            {/* <Link to="/blog" className="nav-link font-press-start">
              Блог
            </Link> */}
            <a
              href="https://shop.lansfloart.ru/"
              className="nav-link font-press-start"
            >
              Магазин
            </a>
            <Link to="/gallery" className="nav-link font-press-start">
              Галерея
            </Link>
            <Link to="/contact" className="nav-link font-press-start">
              Связаться с нами
            </Link>
          </nav>
        </div>

        {/* <div
          className={
            open
              ? "opacity-100 scale-100 transition ease-out duration-200 absolute top-0 inset-x-0 p-2  transform origin-top-right md:hidden"
              : "opacity-0 scale-95 absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
          }
          
        >
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-[#cabdb4] divide-y-2 divide-gray-50">
            <div className="px-5 pt-5 pb-6">
              <div className="flex items-center justify-between">
                <div>
                  <img
                    className="w-auto h-8"
                    src={Logo}
                    alt="Workflow"
                  />
                </div>
                <div className="-mr-2 bg-[#cabdb4]">
                  <button
                    type="button"
                    className="bg-[#cabdb4] rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                    onClick={() => setOpen(!open)} 
                  >
                    <span className="sr-only">Close menu</span>
                    <svg
                      className="w-6 h-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>
             
            </div>
            <div className="py-6 px-5 space-y-6 bg-[#cabdb4]">
              <div className="grid grid-cols-2 gap-y-4 gap-x-8 ">
              <Link
                to="/"
                className="nav-link font-press-start"
                onClick={()=>Close()}
              >
                Главная
              </Link>
              <Link
                to="/about"
                onClick={()=>Close()}
                className="nav-link font-press-start"
              >
                О нас
              </Link>
              <Link
              onClick={()=>Close()}
                to="/service"
                className="nav-link font-press-start"
              >
                Услуги
              </Link>
              <Link
              onClick={()=>Close()}
                to="/blog"
                className="nav-link font-press-start"
              >
                Блог
              </Link>
              <a
                href="https://shop.lansfloart.ru/"
                className="nav-link font-press-start"
              >
                Магазин
              </a>
              <Link
                to="/gallery"
                onClick={()=>Close()}
                className="nav-link font-press-start"
              >
                Галерея
              </Link>
              <Link
                to="/contact"
                onClick={()=>Close()}
                className="nav-link font-press-start"
              >
                Связаться с нами
              </Link>
             
              </div>
            
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
}
export default Header;
