import React from "react";
import { Link } from "react-router-dom";
import Blog1Img from "../../../assets/img/home/blog1.webp";
import Blog2Img from "../../../assets/img/home/blog2.webp";
import Blog3Img from "../../../assets/img/home/blog3.webp";
import { data } from "../../../data/category";
import CategoryImage from "../../../assets/img/home/category1.jpg";
import CategoryImage1 from "../../../assets/img/home/category2.jpg";

function HomeBlogs() {
  return (
    <>
      <div className="bg-[#cabdb4] py-10">
        <div className="max-w-screen-xl border-py-4 mx-auto uppercase text-center font-press-start text-2xl font-bold">
          Категория цветов
        </div>
        <div className="mt-8 container mx-auto gap-x-8  grid md:grid-cols-2 lg:grid-cols-4">
          {data &&
            data.map((item, index) => {
              return (
                <div className="group cursor-pointer">
                  <div className="overflow-hidden rounded-lg">
                    <img
                      src={`${item.image}`}
                      className="transition-all duration-300  group-hover:scale-105"
                    />
                  </div>
                  <div>
                    <p className="relative py-2 hover:-translate-y-1 transition-all duration-300">
                      <Link
                        to={item.href}
                        className={
                          "font-press-start-lato text-black font-bold  uppercase  "
                        }
                      >
                        {item.category}
                      </Link>
                    </p>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
}
export default HomeBlogs;
