import React from "react";
import LocImg from "../assets/img/location.png";
import Telimg from "../assets/img/phone.png";
import Envimg from "../assets/img/envelope.png";
import Instaimg from "../assets/img/insta.png";
import { Link } from "react-router-dom";
function Contact() {
  return (
    <>
      <div className="bg-[#cabdb4] md:py-36">
        <div className="max-w-screen-xl mx-auto flex flex-col md:flex-row">
          <div
            className={
              "w-[90%] sm:w-[80%] mx-auto md:w-1/2 bg-[#848e83] p-6 rounded-l-xl rounded-r-xl md:rounded-r-none"
            }
          >
            <h1 className="font-bold text-white font-press-start">
              Связаться с нами
            </h1>
            <form>
              <input
                placeholder="Имя Фамилия"
                name="name"
                className="bg-transparent border-b-2 focus:outline-none focus:border-b-2 w-full focus:border-[#6A6E49] text-[#6A6E49] py-3"
              />
              <input
                placeholder="электронная почта"
                name="name"
                className="bg-transparent border-b-2 focus:outline-none focus:border-b-2 w-full focus:border-[#6A6E49] text-[#6A6E49] py-3 capitalize"
              />
              <textarea
                name="message"
                placeholder="сообщение "
                className="bg-transparent border-b-2 focus:outline-none focus:border-b-2 w-full focus:border-[#6A6E49] text-[#6A6E49] py-3 capitalize"
              ></textarea>

              <button className="rounded-xl w-full py-3 font-bold text-white font-press-start mt-3 bg-[#6A6E49]">
                Отправить
              </button>
            </form>
          </div>
          <div className="w-[90%] sm:w-[80%] mx-auto md:w-1/2 max-md:mt-5 bg-[#9a8a8b] rounded-r-xl rounded-l-xl md:rounded-l-none   px-10 py-10">
            <ul className="mt-5 flex flex-col space-y-3">
              <li className="flex font-bold font-press-start group cursor-pointer">
                <img
                  src={LocImg}
                  className="transition-all duration-300 group-hover:-translate-y-2"
                />{" "}
                <span className="ml-4">Херсонская 18</span>
              </li>
              <li className="flex font-bold font-press-start group cursor-pointer">
                <img
                  src={Telimg}
                  className="transition-all duration-300 group-hover:-translate-y-2 w-6 rotate-180"
                />
                <a href={"tel:+79854359584"} className={"ml-4"}>
                  +79854359584
                </a>
              </li>
              <li className="flex font-bold font-press-start group cursor-pointer">
                <img
                  src={Instaimg}
                  className="transition-all duration-300 group-hover:-translate-y-2 w-6 "
                />
                <a
                  href={
                    "https://www.instagram.com/lansfloart/?igshid=NmE0MzVhZDY%3D"
                  }
                  className={"ml-4"}
                >
                  lansfloart
                </a>
              </li>
              <li className="flex font-bold font-press-start group cursor-pointer">
                <img
                  src={Envimg}
                  className="transition-all duration-300 group-hover:-translate-y-2 w-6 "
                />
                <a href={"mailto:info@lansfloart.ru"} className={"ml-4"}>
                  info@lansfloart.ru
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
export default Contact;
