import React from "react";
import { Link } from "react-router-dom";
import { data } from "../../../data/home";

import Shop1 from "../../../assets/img/home/1-420x420.webp";
function HomeShop() {
  return (
    <>
      <div className="w-full bg-[#cabdb4] pt-10 pb-20">
        <div className="text-3xl text-white font-press-start text-center py-5">
          Магазин
        </div>
        <div className="container mx-auto py-5 grid md:grid-cols-2 lg:grid-cols-4">
          {data &&
            data.map((item, index) => {
              return (
                <div className=" p-4" key={index}>
                  <div className="w-full">
                    <div>
                      <img src={item.image} alt={""} />
                    </div>
                    <div className="bg-[#6A6E49]  w-full capitalize tracking-wider text-lg font-bold text-white text-center flex items-center justify-center  font-press-start h-16">
                      {item.name}
                    </div>
                    <div className="bg-[#E6E8D6] flex justify-between text-lg font-bold text-black  py-2 font-press-start">
                      <div className="px-2">{item.price}</div>
                      <div className="px-2">
                        <a
                          href={item.href}
                          target="_blank"
                          className="transition-all duration-300 text-lg font-bold text-black py-2 font-press-start hover:text-blue-900"
                        >
                          Купить
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
}
export default HomeShop;
