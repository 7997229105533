import React from "react";
import HomeFirst from "./Components/HomeComponents/homeFirst";
import "../assets/js/script";
import HomeShop from "./Components/HomeComponents/homeShop";
import HomeService from "./Components/HomeComponents/homeService";
import HomeAbout from "./Components/HomeComponents/homeAbout";
import HomeBlogs from "./Components/HomeComponents/homeBlogs";
import HomeKnown from "./Components/HomeComponents/homeKnow";
function Home() {
    return(
        <>
            <HomeFirst />
            <HomeShop />
            <HomeService />
            <HomeAbout />
            <HomeBlogs />
            <HomeKnown />
        </>
    )
}
export default Home