export const data = [
  {
    image:
      "https://shop.lansfloart.ru/wp-content/uploads/2024/02/IMG_9748-scaled.jpg",
    href: "https://shop.lansfloart.ru/product-category/%d0%b2%d1%81%d0%b5-%d1%86%d0%b2%d0%b5%d1%82%d1%8b/",
    category: "Посмотреть все цветы",
  },
  {
    image:
      "https://shop.lansfloart.ru/wp-content/uploads/2024/02/online-scaled.jpg",
    href: "https://shop.lansfloart.ru/product-category/%d0%be%d0%bd%d0%bb%d0%b0%d0%b9%d0%bd-%d0%b2%d0%b8%d1%82%d1%80%d0%b8%d0%bd%d0%b0/",
    category: "онлайн витрина",
  },
  {
    image:
      "https://shop.lansfloart.ru/wp-content/uploads/2024/02/IMG_9706-1-scaled.jpg",
    href: "https://shop.lansfloart.ru/product-category/%d0%bc%d0%be%d0%bd%d0%be-%d0%b1%d1%83%d0%ba%d0%b5%d1%82%d1%8b/",
    category: "Моно букеты",
  },
  {
    image:
      "https://shop.lansfloart.ru/wp-content/uploads/2024/02/IMG_7621-scaled.jpg",
    href: "https://shop.lansfloart.ru/product-category/%d0%b4%d1%83%d0%be-%d0%b8-%d1%82%d1%80%d0%b8%d0%be%d0%b1%d1%83%d0%ba%d0%b5%d1%82%d1%8b/",
    category: "Дуо и трио-букеты",
  },
];
