export const data = [
  {
    id: "Сборка-букетов-и-композиции",
    title: "Сборка букетов и композиции",
    image:
      "https://shop.lansfloart.ru/wp-content/uploads/2024/02/IMG_4872-scaled.jpg",
    images: [
      {
        href: "https://shop.lansfloart.ru/wp-content/uploads/2024/02/IMG_4872-scaled.jpg",
        alt: "Service Image",
      },
      {
        href: "https://shop.lansfloart.ru/wp-content/uploads/2024/02/IMG_5008-scaled.jpg",
        alt: "Service Image",
      },
      {
        href: "https://shop.lansfloart.ru/wp-content/uploads/2024/02/IMG_5410-scaled.jpg",
        alt: "Service Image",
      },
      {
        href: "https://shop.lansfloart.ru/wp-content/uploads/2024/02/IMG_6112-scaled.jpg",
        alt: "Service Image",
      },
      {
        href: "https://shop.lansfloart.ru/wp-content/uploads/2024/02/IMG_6113-scaled.jpg",
        alt: "Service Image",
      },
      {
        href: "https://shop.lansfloart.ru/wp-content/uploads/2024/02/IMG_6923-scaled.jpg",
        alt: "Service Image",
      },
    ],
  },
  {
    id: "Оформление-мероприятие",
    title: "Оформление мероприятие",
    image:
      "https://shop.lansfloart.ru/wp-content/uploads/2024/02/IMG_0854-scaled.jpg",
    images: [
      {
        href: "https://shop.lansfloart.ru/wp-content/uploads/2024/02/IMG_0854-scaled.jpg",
        alt: "Service Image",
      },
      {
        href: "https://shop.lansfloart.ru/wp-content/uploads/2024/02/IMG_0857-scaled.jpg",
        alt: "Service Image",
      },
      {
        href: "https://shop.lansfloart.ru/wp-content/uploads/2024/02/IMG_0860-scaled.jpg",
        alt: "Service Image",
      },
      {
        href: "https://shop.lansfloart.ru/wp-content/uploads/2024/02/IMG_1621-scaled.jpg",
        alt: "Service Image",
      },
      {
        href: "https://shop.lansfloart.ru/wp-content/uploads/2024/02/IMG_1623-scaled.jpg",
        alt: "Service Image",
      },
      {
        href: "https://shop.lansfloart.ru/wp-content/uploads/2024/02/IMG_2198-scaled.jpg",
        alt: "Service Image",
      },
      {
        href: "https://shop.lansfloart.ru/wp-content/uploads/2024/02/IMG_0710.jpg",
        alt: "Service Image",
      },
    ],
  },
  {
    id: "Надувание-Шаров",
    title: "Надувание Шаров",
    image:
      "https://shop.lansfloart.ru/wp-content/uploads/2024/02/IMG_5034-scaled.jpg",
    images: [
      {
        href: "https://shop.lansfloart.ru/wp-content/uploads/2024/02/IMG_5034-scaled.jpg",
        alt: "Service Image",
      },
      {
        href: "https://shop.lansfloart.ru/wp-content/uploads/2024/02/IMG_5038-scaled.jpg",
        alt: "Service Image",
      },
      {
        href: "https://shop.lansfloart.ru/wp-content/uploads/2024/02/IMG_5057-scaled.jpg",
        alt: "Service Image",
      },
    ],
  },
];
