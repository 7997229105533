import Recat from "react";
import { Link } from "react-router-dom";
import Service1Img from '../assets/img/home/service1.webp';
function Service() {
    return (
        <>
            <div className="w-full py-12 bg-[#6A6E49]">
                <div className="max-w-screen-lg mx-auto">
                    <h1 className="text-center font-press-start font-bold text-white text-2xl">Услуги</h1>
                   
                </div>

                <div className="max-w-screen-lg mx-auto">
                    <div className="w-full flex flex-col md:flex-row p-4 mt-5 justify-center md:shadow-[0_15px_60px_-15px_rgba(202,189,180,1)] rounded-xl">
                        <div className="w-full md:w-1/4 flex items-center justify-center">
                            <img src={Service1Img} className={'w-48 h-48'} />
                        </div>
                        <div className="w-full md:w-3/4 p-4 md:p-12">
                            <h1 className="font-press-start font-bold text-white text-2xl">Сборка букетов и композиции</h1>
                            <p className="text-justify font-semibold font-press-start-lato my-5">
                                
                            </p>
                        </div>
                    </div>
                    <div className="w-full flex flex-col md:flex-row p-4 mt-5 justify-center md:shadow-[0_15px_60px_-15px_rgba(202,189,180,1)] rounded-xl">
                       
                        <div className="w-full md:w-3/4 p-4 md:p-12">
                            <h1 className="font-press-start font-bold text-white text-2xl">Оформление мероприятие</h1>
                            <p className="text-justify font-semibold font-press-start-lato my-5">
                                
                            </p>
                        </div>
                        <div className="w-full md:w-1/4 flex items-center justify-center">
                            <img src={Service1Img} className={'w-48 h-48'} />
                        </div>
                    </div>
                     <div className="w-full flex flex-col md:flex-row p-4 mt-5 justify-center md:shadow-[0_15px_60px_-15px_rgba(202,189,180,1)] rounded-xl">
                        <div className="w-full md:w-1/4 flex items-center justify-center">
                            <img src={Service1Img} className={'w-48 h-48'} />
                        </div>
                        <div className="w-full md:w-3/4 p-4 md:p-12">
                            <h1 className="font-press-start font-bold text-white text-2xl">Надувание Шаров </h1>
                            <p className="text-justify font-semibold font-press-start-lato my-5">
                                
                            </p>
                        </div>
                    </div>
                    <div className="w-full flex flex-col md:flex-row p-4 mt-5 justify-center md:shadow-[0_15px_60px_-15px_rgba(202,189,180,1)] rounded-xl">
                        
                        <div className="w-full md:w-3/4 p-4 md:p-12">
                            <h1 className="font-press-start font-bold text-white text-2xl">Игрушки и подарки </h1>
                            <p className="text-justify font-semibold font-press-start-lato my-5">
                                
                            </p>
                        </div>
                        <div className="w-full md:w-1/4 flex items-center justify-center">
                            <img src={Service1Img} className={'w-48 h-48'} />
                        </div>
                    </div>


                    <div className="w-full flex flex-col md:flex-row p-4 mt-5 justify-center md:shadow-[0_15px_60px_-15px_rgba(202,189,180,1)] rounded-xl">
                        <div className="w-full md:w-1/4 flex items-center justify-center">
                            <img src={Service1Img} className={'w-48 h-48'} />
                        </div>
                        <div className="w-full md:w-3/4 p-4 md:p-12">
                            <h1 className="font-press-start font-bold text-white text-2xl">Декор для вашего дома</h1>
                            <p className="text-justify font-semibold font-press-start-lato my-5">
                                
                            </p>
                        </div>
                    </div>


                </div>
            </div>
        </>
    )
}
export default Service