import React from "react"
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import GalleryImg1 from "../../../assets/img/home/image-030.jpg";
import GalleryImg2 from "../../../assets/img/home/image-031.jpg";
import GalleryImg3 from "../../../assets/img/home/image-032.jpg";
import GalleryImg4 from "../../../assets/img/home/image-033.jpg";
function HomeKnown(){
    return(
        <>
            <div className="bg-[#E6E8D6] py-10">
                <div className="max-w-screen-lg py-4 mx-auto uppercase text-center font-press-start text-2xl font-bold">Полезно знать</div>
                <div className="max-w-screen-xl mx-auto mt-5">
                <LightGallery
                speed={500}
                plugins={[lgThumbnail, lgZoom]}
                className='bg-dark'
            >
                {
                    // images && images.map((item , index) => {
                    //     return <a href={`https://a9jueqat.directus.app/assets/${item.gallery_image}`} key={index}>
                    //     <img alt={item.gallery_title} src={`https://a9jueqat.directus.app/assets/${item.gallery_image}`} />
                    // </a>
                    // })
                }
                <a href={GalleryImg1} key={""} className={'w-1/4 p-2'}>
                        <img alt={""} src={GalleryImg1}  />
                 </a>
                <a href={GalleryImg2} key={""} className={'w-1/4 p-2'}>
                        <img alt={""} src={GalleryImg2}   />
                 </a>
                <a href={GalleryImg3} key={""} className={'w-1/4 p-2'}>
                        <img alt={""} src={GalleryImg3}   />
                 </a>
                <a href={GalleryImg4} key={""} className={'w-1/4 p-2'}>
                        <img alt={""} src={GalleryImg4}   />
                 </a>
            </LightGallery>
                </div>
            </div>
        </>
    )
}
export default HomeKnown